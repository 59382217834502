import React, { Component } from 'react';
import { withStyles, createStyles, WithStyles, Paper, Typography, Select, FormControl, Input, MenuItem, Theme, Divider, Button, FormLabel, InputLabel } from '@material-ui/core'
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { submitAnswer } from './service'

const styles = ({ palette, spacing }: Theme) => createStyles({
    paper: {
        maxWidth: '600px',
        maxHeight: '100%',
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.9);',
        overflowY: 'auto',
    },
    formControl: {
        margin: spacing.unit,
        width: '100%',
        float: 'left',
        marginTop: 20,
    },
    formText: {
        fontSize: '16px',
        float: 'left'
    },
    textBox: {
        marginTop: '-0.6em'
    },
    divider: {
        marginTop: '180px;',
        marginBottom: '10px'
    },
    signature: {
        float: 'right',
        marginTop: "40px;",
        marginRight: "10%"
    },
    image: {
        marginTop: '20px'
    },
    button: {
        margin: spacing.unit,
        float: 'right',
        width: '250px',
        marginBottom: '35px'
    },
    additionalText: {
        fontSize: '0.8em',
        color: 'red'
    },
    buttonSelect: {
        height: '30px',
        paddingTop: '5px'
    },
    map: {
        width: '100%',
        height: '160px',
        marginTop: '15px',
        marginBottom: '25px'
    }

});
interface IMatchParams {
    name: string;
}

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<IMatchParams> {

}
interface IState {
    selectedNumber: string;
    additionalFromText: string;
    isSent: boolean;
    name: string;
}

class Main extends Component<IProps, IState> {
    readonly allText = 'i nimm sganze dorf mit';
    readonly additionalText = 'und bringe dafür essa für alli mit'
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedNumber: '',
            additionalFromText: '',
            isSent: false,
            name: ''
        }

    }

    componentDidMount() {
        this.getData();

    }


    render() {

        const { paper, formControl, additionalText, textBox, divider, signature, image, button, buttonSelect, map } = this.props.classes
        const number = ["", "i chume allei", "Mier chömed szweita", "Mier chömed zdritte", "Mier chömed zvierta", this.allText, "i kann leider nid cho"];
        return (
            <Paper className={paper}>
                <Typography align="left" gutterBottom variant="h4">
                    Iladig zum WG-Iweiigstag
                        </Typography>
                <Typography align="left" gutterBottom variant="h6">
                    {this.validateName(this.props.match.params.name)}
                </Typography>
                <Typography align="left" color="textSecondary">
                    Gera laden mier dii und dini lieba Lüt ii, uf üsi neui WG azstosse. Am Samstig de <b>13. April</b>, abem <b>10i</b> am morge, bis es ziit isch zum go schlofe, sind mier für dii do.
                            Öppis kliises zessa und öpis zum trinka werdemer au für dii parat ha. Du finsch üs ade <b>Cadonaustrasse 65 in Chur</b>
                </Typography>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1621.8367009329418!2d9.53776712364576!3d46.87137475316173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784c79248303de1%3A0x8fe97719e03b262e!2sCadonaustrasse+65%2C+7000+Chur!5e0!3m2!1sde!2sch!4v1554050731239!5m2!1sde!2sch"
                    className={map} frameBorder="0"></iframe>

                <Typography align="left" color="textSecondary">
                    Damit mier achli wüssen wia viil Essa mier vorbereita dörfen, meld di und dini Liebschta doch mit folgendem Formular ah.
                        </Typography>
                <div className={signature}>
                    <Typography align="left" color="textSecondary">
                        Mier freuen üs uf di/eu
                            </Typography>
                    <img className={image} src="/unterschrift.png" alt="Nino, Dumeni" width="194px" height="57px" />
                </div>
                <Divider className={divider} variant="middle" />
                <FormControl className={formControl}>
                    <InputLabel htmlFor="select-number">
                        Wer chund alles?
                            </InputLabel>
                    <Select
                        disabled={this.state.isSent}
                        value={this.state.selectedNumber}
                        onChange={this.handleChange}
                        inputProps={{
                            name: 'number',
                            id: 'select-number'
                        }}
                        className={textBox}
                        classes={{
                            select: buttonSelect
                        }}

                    >
                        {number.map(n => (
                            <MenuItem key={n} value={n}>
                                {n}
                            </MenuItem>
                        ))}
                    </Select>
                    <span className={additionalText}>{this.state.additionalFromText}</span>
                    <Button disabled={this.state.isSent || this.state.selectedNumber === ''} variant="contained" color="primary" className={button} onClick={this.handleFormularSend}>
                        <img src="/10279.png" width="25px" height="25px" />&nbsp;&nbsp;brieftuba los schigge
                                </Button>
                </FormControl>
            </Paper>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            selectedNumber: event.target.value,
            additionalFromText: event.target.value === this.allText ? this.additionalText : ''
        });
    };

    handleFormularSend = (event: any) => {
        submitAnswer(atob(this.props.match.params.name), this.state.selectedNumber)
        window.localStorage.setItem('selectedValue', this.state.selectedNumber)
        window.localStorage.setItem('name', this.props.match.params.name)
        this.setState({
            isSent: true
        })
    }

    getData = () => {
        var selectedValue = window.localStorage.getItem('selectedValue');
        if (selectedValue === null || window.localStorage.getItem('name') !== this.props.match.params.name) {
            this.setState({
                selectedNumber: '',
            })
            return
        }
        this.setState({
            selectedNumber: selectedValue,
            isSent: true,
            additionalFromText: selectedValue === this.allText ? this.additionalText : ''

        })
    }

    validateName(propsName: string) {
        try {
            return atob(propsName)
        } catch{
            return <Redirect to="/" />
        }
    }
}

export default withStyles(styles)(Main); 