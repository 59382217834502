
declare global {
    interface Window { 
        requestAnimFrame: any;
        mozRequestAnimationFrame: any;
    
    }
}

export class Party {
   canvas: any
   ctx: any
   config = {
    particleNumber: 200,
    maxParticleSize: 10,
    maxSpeed: 40,
    colorVariation: 50
  };
   colorPalette = {
    bg: { r: 12, g: 9, b: 29 },
   }

    particles: Particle[];
   
    constructor (){
        this.particles = [];
        // Littlethis.canvas things
        this.canvas = document.querySelector("#canvas")
        this.ctx = this.canvas.getContext('2d');
        // Setthis.canvas to be window size
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        window.requestAnimFrame = (function () {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
        })();
        this.frame();
        this.initParticles(this.config.particleNumber, this.canvas.width / 2, this.canvas.height / 2);      
        document.body.addEventListener("click", (event: any) => {
            var x = event.clientX,
                y = event.clientY;
            this.cleanUpArray();
            this.initParticles(this.config.particleNumber, x, y);
        });
        window.addEventListener('resize', evt => {
            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight;
        })
    }


     drawBg = (ctx: any, color: any)  => {
            ctx.fillStyle = "rgb(" + color.r + "," + color.g + "," + color.b + ")";
            ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        };

    updateParticleModel = (p: any) => {
        var a = 180 - (p.d + 90); // find the 3rd angle
        p.d > 0 && p.d < 180 ? p.x += p.s * Math.sin(p.d) / Math.sin(p.s) : p.x -= p.s * Math.sin(p.d) / Math.sin(p.s);
        p.d > 90 && p.d < 270 ? p.y += p.s * Math.sin(a) / Math.sin(p.s) : p.y -= p.s * Math.sin(a) / Math.sin(p.s);
        return p;
    };


    drawParticle = (x: any, y: any, r: any, c: any) => {
        this.ctx.beginPath();
        this.ctx.fillStyle = c;
        this.ctx.arc(x, y, r, 0, 2 * Math.PI, false);
        this.ctx.fill();
        this.ctx.closePath();
    };


    cleanUpArray = ()  => {
        this.particles = this.particles.filter((p: any) => {
            return (p.x > -100 && p.y > -100);
        });
    };


    initParticles = (numParticles: number, x = 0, y = 0) => {
        for (let i = 0; i < numParticles; i++) {
            this.particles.push(new Particle(x, y, this.canvas.width, this.canvas.height, this.config));
        }
        this.particles.forEach((p: Particle) => {
            this.drawParticle(p.x, p.y, p.r, p.c);
        });
    };

    frame = () => {

        this.drawBg(this.ctx, this.colorPalette.bg);
        this.particles = this.particles.filter(
            (p) => p.x > 0 && p.y > 0 && p.x < this.canvas.width && p.y < this.canvas.height
        ).map((p) => {
            return this.updateParticleModel(p);
        });
        this.particles.forEach((p: Particle) => {
            this.drawParticle(p.x, p.y, p.r, p.c);
        });
        window.requestAnimFrame(this.frame);
    };

}

export class Particle {
    colorPalette = {
        bg: { r: 12, g: 9, b: 29 },
        matter: [
            { r: 36, g: 18, b: 42 }, // darkPRPL
            { r: 78, g: 36, b: 42 }, // rockDust
            { r: 252, g: 178, b: 96 }, // solorFlare
            { r: 253, g: 238, b: 152 } // totesASun
        ]
    };
    config: any
    public c: any;
    public x: number;
    public y: number;
    public r: number;
    public s: number;
    public d: number;
    constructor (x: number, y: number, width: any, height: any, config: any) {
        this.config = config
        // X Coordinate
        this.x = x || Math.round(Math.random() * width);
        // Y Coordinate
        this.y = y || Math.round(Math.random() * height);
        // Radius of the space dust
        this.r = Math.ceil(Math.random() * config.maxParticleSize);
        // Color of the rock, given some randomness
        this.c = this.colorVariation(this.colorPalette.matter[Math.floor(Math.random() * this.colorPalette.matter.length)], true);
        // Speed of which the rock travels
        this.s = Math.pow(Math.ceil(Math.random() * config.maxSpeed), .7);
        // Direction the Rock flies
        this.d = Math.round(Math.random() * 360);
    }
    colorVariation = (color: any, returnString: any) => {
        var r, g, b, a, variation;
        r = Math.round(((Math.random() *this.config.colorVariation) - (this.config.colorVariation / 2)) + color.r);
        g = Math.round(((Math.random() *this.config.colorVariation) - (this.config.colorVariation / 2)) + color.g);
        b = Math.round(((Math.random() *this.config.colorVariation) - (this.config.colorVariation / 2)) + color.b);
        a = Math.random() + .5;
        if (returnString) {
            return "rgba(" + r + "," + g + "," + b + "," + a + ")";
        } else {
            return { r, g, b, a };
        }
    };
};