import React, { Component } from 'react';
import { withStyles, createStyles, WithStyles, Paper,  Theme, TextField, Link, } from '@material-ui/core'

const styles = ({ palette, spacing }: Theme) => createStyles({
    paper: {
        maxWidth: '600px',
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.9);'
    },
    div: {
        width: "100%",
        overflowWrap: "break-word",
        wordWrap: "break-word"
    },
    container:{
        maxWidth: "100%"
    },
    textbox: {
        width: "100%"
    }
});

interface IProps extends WithStyles<typeof styles> {

}
interface IState {
    name: string;
    link: string;
}

class LinkCreator extends Component<IProps, IState> {
    constructor(props: IProps){
        super(props)
        this.state = {
            name: '',
            link: ''
        }
    }
    render() {
        const { paper, div, container, textbox} = this.props.classes
        return (
            <div className={container}>
            <Paper className={paper}>
                <form noValidate autoComplete="off">
                    <TextField
                        id="standard-name"
                        label="Name mit Anrede"
                        value={this.state.name}
                        onChange={this.handleChange}
                        margin="normal"
                        className={textbox}
                    />
                </form>
                <div className={div}>
                <p>
                    <Link href={this.state.link}>
                        {this.state.link}
                    </Link> 
                    </p>
                </div>
            </Paper>
            </div>

        );
    }
    handleChange = (event: any) => {
        this.setState({
            link: "http://" + window.location.host + "/" + window.btoa(event.target.value),
            name: event.target.value
        })
    }
}

export default withStyles(styles)(LinkCreator); 