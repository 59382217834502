import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'; 
import Main from './Main';
import InvalidLink from './InvalidLink'
import './App.css';
import { Party } from './Background/Party'
import LinkCreator from './LinkCreator';
class App extends Component {
  party: any
  constructor(props: any){
    super(props);
    this.party = new Party();
  }
  render() {    
    return (
      <div className="App">
        <main className="App-header">
							<Switch>
                <Route exact path='/' component={InvalidLink} />
                <Route exact path="/linkCreator" component={LinkCreator} />
                <Route path='/:name' component={Main} />
            </Switch>
        </main>
      </div>
    );
  }
}

export default App;
 