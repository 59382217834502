import React, { Component } from 'react';
import { withStyles, createStyles, WithStyles, Paper, Typography,  Theme, } from '@material-ui/core'

const styles = ({ palette, spacing }: Theme) => createStyles({
    paper: {
        maxWidth: '600px',
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.9);'
    },
});

interface IProps extends WithStyles<typeof styles> {

}
interface IState {
    selectedNumber: string;
    additionalFromText: string;
    isSent: boolean;
}

class InvalidLink extends Component<IProps, IState> {
    componentDidMount() {
        this.getData();
    }
    render() {
        const { paper} = this.props.classes
        return (
            <Paper className={paper}>
                <Typography align="left" gutterBottom variant="h4">
                    404 - Ops din link isch wohl falsch, frog doch nomol noche ob nid en gültige link kriegsch
                        </Typography>

            </Paper>

        );
    }
    getData = () => {
        var selectedValue = window.localStorage.getItem('selectedValue');
        if (selectedValue === null) {
            return
        }
        console.log('redirect...')
    }
}

export default withStyles(styles)(InvalidLink); 