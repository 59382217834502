import  axios  from 'axios';

axios.defaults.baseURL = 'https://party-backend20190401085619.azurewebsites.net/api'
export const submitAnswer = (name: string, answer: string) =>{
    axios.post('/party', {
        Name: name,
        Answer: answer
    })
    .then(function (response: any) {
        console.log(response);
    })
    .catch(function (error: any) {
        console.log(error);
  })
}
